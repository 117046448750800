import { insertData } from '@/services/commonService';

export default class SmsService {
    #defaultUrl = '/api/Sms';
    #defaultResourceName = 'Sms';

    async sendSingleSms(dataItem) {
        const createData = {
            'to': dataItem.phoneNumber,
            'message': dataItem.text,
        };
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async sendEmployeeSms(employeeId, text, projectId) {
        const createData = {
            'projectId': projectId,
            'message': text,
            'departmentName': '',
            'employeeIds': [employeeId],
        };
        return await insertData(this.#defaultUrl + '/send-to-employee', createData, this.#defaultResourceName);
    }

    async sendNewEmployeeSms(projectEmployeeId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId
        };
        return await insertData(this.#defaultUrl + '/send-new-employee-add', createData, this.#defaultResourceName);
    }

    async sendEmployeeRemoveMessage(projectEmployeeId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId
        };
        return await insertData(this.#defaultUrl + '/send-employee-remove', createData, this.#defaultResourceName);
    }

    async sendEmployeeNewAllowanceMessage(projectEmployeeId, allowanceId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId,
            "allowanceId" : allowanceId
        };
        return await insertData(this.#defaultUrl + '/send-employee-new-allowance', createData, this.#defaultResourceName);
    }

    async sendEmployeeNewBonusMessage(projectEmployeeId, bonusId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId,
            "bonusId" : bonusId
        };
        return await insertData(this.#defaultUrl + '/send-employee-new-bonus', createData, this.#defaultResourceName);
    }



    async sendEmployeeNewDayOffMessage(projectEmployeeId, dayOffId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId,
            "dayOffId" : dayOffId
        };
        return await insertData(this.#defaultUrl + '/send-employee-new-dayOff', createData, this.#defaultResourceName);
    }

    async sendEmployeeNewOvertimeMessage(projectEmployeeId, overtimeId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId,
            "overtimeId" : overtimeId
        };
        return await insertData(this.#defaultUrl + '/send-employee-new-overtime', createData, this.#defaultResourceName);
    }

    async sendEmployeeNewSalaryMessage(projectEmployeeId) {
        const createData = {
            'projectEmployeeId': projectEmployeeId
        };
        return await insertData(this.#defaultUrl + '/send-employee-new-salary', createData, this.#defaultResourceName);
    }

    async sendEmployeeDepartmentSms(departmentName, text, projectId) {
        const createData = {
            'projectId': projectId,
            'message': text,
            'departmentName': departmentName,
            'employeeIds': [],
        };
        return await insertData(this.#defaultUrl + '/send-to-employee', createData, this.#defaultResourceName);
    }

    async sendAllEmployeeSms(text)
    {
        const createData = {
            'message': text,
            'employeeIds': [],
        };
        return await insertData(this.#defaultUrl + '/send-to-employee', createData, this.#defaultResourceName);
    }
    async sendEmployeeProjectSms(text, projectId) {
        const createData = {
            'projectId': projectId,
            'message': text,
            'departmentName': '',
            'employeeIds': [],
        };
        return await insertData(this.#defaultUrl + '/send-to-employee', createData, this.#defaultResourceName);
    }

    async sendAllFirmSms(text, projectId) {
        const createData = {
            'projectId': projectId,
            'message': text,
            'firmIds': [],
        };
        return await insertData(this.#defaultUrl + '/send-to-firm', createData, this.#defaultResourceName);
    }

    async sendSelectedFirmSms(firmId, text, projectId) {
        const createData = {
            'projectId': projectId,
            'message': text,
            'firmIds': [firmId],
        };
        return await insertData(this.#defaultUrl + '/send-to-firm', createData, this.#defaultResourceName);
    }

    async sendAllManagerUserSms(text) {
        const createData = {
            'message': text,
            'managerUserIds': [],
        };
        return await insertData(this.#defaultUrl + '/send-to-manager-user', createData, this.#defaultResourceName);
    }

    async sendSelectedManagerUserSms(managerUserId, text) {
        const createData = {
            'message': text,
            'managerUserIds': [managerUserId],
        };
        return await insertData(this.#defaultUrl + '/send-to-manager-user', createData, this.#defaultResourceName);
    }
}