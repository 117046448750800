import {
    deleteDataById,
    getData,
    getDataBydId,
    getExcel,
    getPaginatedData,
    insertData,
    patchData,
} from './commonService';

export default class FirmService {


    #defaultUrl = "/api/Firm";
    #defaultResourceName = "Firma";

    async getAllFirms() {
        return await getPaginatedData(this.#defaultUrl, this.#defaultResourceName);
    }

    async getAllFirmsWithProjectId(projectId) {
        return await getPaginatedData(this.#defaultUrl + "/query?ProjectId=" + projectId, this.#defaultResourceName);
    }

    async getAllFirmsWithProjectIdForDefaults(projectId) {
        return await getPaginatedData(this.#defaultUrl + "/query?isActive=true&ProjectId=" + projectId, this.#defaultResourceName);
    }

    async getAllFirmsWithProjectIdExcel(projectId) {
        return await getExcel(this.#defaultUrl + "/excel?ProjectId=" + projectId, this.#defaultResourceName);
    }

    async getAllFirmDefaultsWithProjectIdExcel(projectId) {
        return await getExcel(this.#defaultUrl + "/defaults-excel?ProjectId=" + projectId, this.#defaultResourceName);
    }

    async getFirmDefaultReports(projectId) {
        return await getData(this.#defaultUrl + "/Report/Totals?ProjectId=" + projectId, this.#defaultResourceName);
    }

    async getFirmById(firmId) {
        return await getDataBydId(this.#defaultUrl, firmId, this.#defaultResourceName);
    }

    async checkFirmExists(projectId, phone, email) {
        return await getPaginatedData(this.#defaultUrl + "/query?ProjectId=" + projectId + '&personPhone=' + phone + '&personEmail=' + email, this.#defaultResourceName);
    }

    async insertFirm(dataItem) {
        const createData = {
            "name": dataItem.name,
            "isActive": dataItem.isActive,
            "phone": dataItem.phone,
            "email": dataItem.email,
            "personName": dataItem.personName,
            "personPhone": dataItem.personPhone,
            "personEmail": dataItem.personEmail,
            "projectId": dataItem.projectId,
            "firmLogo": "FirmaLogo/LogoDefault.png",
            "managerUserId": dataItem.managerUserId,
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async updateFirmSettings(firmId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "allowGsm", "value": dataItem.allowGsm},
            {"op": "replace", "path": "allowEmail", "value": dataItem.allowEmail},
        ];

        return await this.patchFirm(firmId, patchDocument);
    }

    async updateFirmDefaults(firmId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "breakfastQuantity", "value": dataItem.breakfastQuantity},
            {"op": "replace", "path": "lunchQuantity", "value": dataItem.lunchQuantity},
            {"op": "replace", "path": "dinnerQuantity", "value": dataItem.dinnerQuantity},
            {"op": "replace", "path": "snackQuantity", "value": dataItem.snackQuantity},
            {"op": "replace", "path": "iftarQuantity", "value": dataItem.iftarQuantity},
            {"op": "replace", "path": "sahurQuantity", "value": dataItem.sahurQuantity},
            {"op": "replace", "path": "nightMealQuantity", "value": dataItem.nightMealQuantity}
        ];

        return await this.patchFirm(firmId, patchDocument);
    }
    async updateFirmOfficial(firmId, dataItem) {
        const patchDocument = [

            {"op": "replace", "path": "taxNumber", "value": dataItem.taxNumber},
            {"op": "replace", "path": "taxOffice", "value": dataItem.taxOffice},
            {"op": "replace", "path": "officialName", "value": dataItem.officialName},
            {"op": "replace", "path": "officialAddress", "value": dataItem.officialAddress},
            {"op": "replace", "path": "officialCityId", "value": dataItem.officialCityId},
            {"op": "replace", "path": "officialTownId", "value": dataItem.officialTownId},
            {"op": "replace", "path": "officialPostalCode", "value": dataItem.officialPostalCode},
            {"op": "replace", "path": "eInvoiceAddress", "value": dataItem.eInvoiceAddress}
        ];

        return await this.patchFirm(firmId, patchDocument);
    }

    async updateFirm(firmId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "name", "value": dataItem.name},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "phone", "value": dataItem.phone},
            {"op": "replace", "path": "personName", "value": dataItem.personName},
            {"op": "replace", "path": "email", "value": dataItem.email},
            {"op": "replace", "path": "personPhone", "value": dataItem.personPhone},
            {"op": "replace", "path": "personEmail", "value": dataItem.personEmail},
            {"op": "replace", "path": "passiveReason", "value": dataItem.passiveReason},
        ];

        if(dataItem.passiveDate != null && dataItem.passiveDate != '')
        {
            patchDocument.push(
                {"op": "replace", "path": "passiveDate", "value": dataItem.passiveDate.valueOf()}
            )
        }
        else {
            patchDocument.push(
                {"op": "replace", "path": "passiveDate", "value": null}
            )
        }

        return await this.patchFirm(firmId, patchDocument);
    }


    async deleteById(firmId) {
        return await deleteDataById(this.#defaultUrl, firmId, this.#defaultResourceName);
    }

    async patchFirm(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}