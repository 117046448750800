<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='profile relative'>
                            <img src='http://image.a1catering.com.tr/YoneticiProfil/ProfilDefault.png' width='100'
                                 class='border-circle border-3 border-solid' />
                        </div>
                    </div>
                </div>

                <span>
					<hr>
				</span>


            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12'>


                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Taşeron<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='department' v-model='selectedFirm' :options='firmList' optionLabel='name' option-value='id' placeholder='Seçim Yapın'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesaj İçeriği<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Textarea rows='5' cols='30' id='name' type='text' autocomplete='off'
                                          v-model='dataItem.text' />
                            </div>
                        </div>


                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-4'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='save' label='Gönder' icon='pi pi-send' @click='save' v-bind:disabled='isProcessing'></Button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>

import { showErrorMessage, showSuccessMessage, showValidationMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import SmsService from '@/services/smsService';
import { getActiveProjectId } from '@/pages/common/commonConstantFunctions';
import FirmService from '@/services/firmService';

export default {
    _smsService: null,
    _firmService:null,
    components: { AdminToast },

    computed:{
        showEmployeeList() {
            return this.selectedDepartment == "Tümü";
        }
    },
    created() {
        this._smsService = new SmsService();
        this._firmService = new FirmService();
        this.projectId = getActiveProjectId();
    },
    async mounted() {
        this.loadingIndicator = true;

        const response = await this._firmService.getAllFirmsWithProjectId(this.projectId);
        this.firmList = response.data;
        this.firmList.push({
            id:0,
            name:"Tümü"
        });
    },


    data() {
        return {
            isProcessing: false,
            display: false,
            visibleLeft: false,
            projectId:0,
            dataItem: {
                phoneNumber: 0,
                text: ''
            },
            selectedFirm:0,
            firmList:[],
        };
    },

    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                if(this.selectedFirm == "0")
                {
                    let insertResponse = await this._smsService.sendAllFirmSms(this.dataItem.text, this.projectId);
                    if (insertResponse.isSuccess) {
                        showSuccessMessage(this, "Sms Gönderilmek üzere sıraya alındı");
                    } else {
                        showErrorMessage(this, 'SMS Göndermede Sorun İle Karşılaşıldı');
                    }
                }
                else if(this.selectedFirm != "0"){
                    let insertResponse = await this._smsService.sendSelectedFirmSms(this.selectedFirm, this.dataItem.text, this.projectId);
                    if (insertResponse.isSuccess) {
                        showSuccessMessage(this, "Sms Gönderilmek üzere sıraya alındı");
                    } else {
                        showErrorMessage(this, 'SMS Göndermede Sorun İle Karşılaşıldı');
                    }
                }

            }
            this.isProcessing = false;
        },
        async validateForm() {

            if (this.dataItem.text == '') {
                showValidationMessage(this, 'Mesaj Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

    },

};

</script>


<style scoped>

</style>
